<template>
    <div>
        <div>
            <label class="font-weight-semibold">
                {{ $trans('Answer') }}:
            </label>
            <i>{{ $trans(reply) }}</i>
        </div>
        <div v-if="!isPassed">
            <label class="font-weight-semibold">
                {{ $trans('Correct') }}:
            </label>
            <i>{{ $trans(value.correct) }}</i>
        </div>
    </div>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'

export default {
    name: 'TrueFalseResult',
    mixins: [questionsTypeBasePropsMixin],
    computed: {
        isPassed() {
            return this.value.correct === this.reply
        }
    }
}
</script>

<style lang=scss>

</style>
